import React, { Component } from "react";
import { Button, Grid } from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L3.scss';

export default class B11U1L3 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

      <Grid>
        <Grid.Column widescreen={1} largeScreen={1} computer={1} tablet={1} mobile={1} >

        </Grid.Column>

        <Grid.Column widescreen={14} largeScreen={14} computer={14} tablet={14} mobile={14} >

          <h2><br></br>M1U2L2</h2>
          <Slider {...settings}>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-0.jpg?alt=media&token=b0e8036d-f469-494e-86e7-75d65e4c3ff1'} alt="boohoo" className="img-responsive" />
              <br />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-1.jpg?alt=media&token=5a2479c3-9f5c-4d18-82a8-99d74e904bf3'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-2.jpg?alt=media&token=3b300053-c185-4773-a55e-ecb72fcab64d'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-3.jpg?alt=media&token=ab69abd2-7d02-4c26-a9ee-6b4f5d253367'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-4.jpg?alt=media&token=2dca8dd0-de63-4311-9b94-d528e41e394b'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-5.jpg?alt=media&token=7144ab8e-b949-46f9-99fd-83f3f3c016c6'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-6.jpg?alt=media&token=3bc02f5f-ae35-4714-86f1-84579d79a08b'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-7.jpg?alt=media&token=4690adf6-6f3d-40bf-91e2-37f0e18ddc90'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-8.jpg?alt=media&token=44457457-e58c-4211-91cd-af9461ee9d43'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-9.jpg?alt=media&token=e1dbfc84-8eb7-44eb-992d-b1827dd3ff41'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-10.jpg?alt=media&token=9d5906a8-3d74-4f02-9845-07a17b50d5af'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-11.jpg?alt=media&token=124f7526-d109-4c5f-8fc3-2f18b44bcd7e'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-12.jpg?alt=media&token=420a17c5-3920-40cd-87c4-696d4803b67e'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-13.jpg?alt=media&token=b6e7606c-544e-4c14-92ac-827ceacc4de6'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-14.jpg?alt=media&token=303a8d6b-dfa0-4277-b040-7a93fa4149e0'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-15.jpg?alt=media&token=3d2b52f8-7c65-4e49-8fa9-105a2b43af39'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-16.jpg?alt=media&token=2ae7f381-4559-451a-a0dc-f96e75504995'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-17.jpg?alt=media&token=1d284171-6286-4081-8733-ad209314a7f0'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-18.jpg?alt=media&token=3cb02884-88bc-4cf9-bfa0-8ca9e9348c29'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-19.jpg?alt=media&token=63e91149-7b1b-42df-9d13-9f34d669525b'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-20.jpg?alt=media&token=3a82644d-5a65-4223-a4e7-686deaa3d2f8'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-21.jpg?alt=media&token=ef5c4388-ad62-4182-9fb4-3db731938e09'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-22.jpg?alt=media&token=7f2c1b38-c413-4f40-9024-94ff9d527d4b'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-23.jpg?alt=media&token=fb836420-fd53-4dd9-93cd-6ba2d9be86e0'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L2%2F3e46be68604744d750893377ab7e65b2-24.jpg?alt=media&token=260cb777-28aa-4a66-a9e7-80c74d9ee6de'} alt="boohoo" className="img-responsive" />
            </div>
            
          </Slider>


        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1} tablet={1} mobile={1} >

        </Grid.Column>
      </Grid>


    );
  }
}


