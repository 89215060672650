import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L10.scss'

export default class B11U1L10 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U6L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-0.jpg?alt=media&token=b419fcfd-5dbf-430c-960a-e0c7f757815f'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-1.jpg?alt=media&token=8b81c554-4e79-4f21-99b0-2e145ebb62be'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-2.jpg?alt=media&token=37032100-766a-4f50-b154-93dad691e6da'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-3.jpg?alt=media&token=d02d2e99-b43f-4250-bc47-541178640417'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-4.jpg?alt=media&token=281dcfdc-06e8-42a1-acd3-2262ad0bf166'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-5.jpg?alt=media&token=d4e6d290-a2f3-44d9-88f5-19fa9d73c39b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-6.jpg?alt=media&token=26f38980-caae-49b4-97c3-8cb108507a6a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-7.jpg?alt=media&token=698a06f9-9585-48fb-8bd9-e9c6f08dfb29'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-8.jpg?alt=media&token=2f6abc16-870e-4d68-8c91-91ca5c1cddc9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-9.jpg?alt=media&token=7f617c42-9bdc-4536-99a3-db4a4ad22855'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-10.jpg?alt=media&token=f7bf72c9-48d4-4a87-b611-27e40112dfdf'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-11.jpg?alt=media&token=0d460b0b-0857-4bf5-ba34-f0cc833aed98'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-12.jpg?alt=media&token=c18b0367-46b8-4019-b56a-44486c7bac83'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-13.jpg?alt=media&token=ec8167ae-8949-4e24-afff-f335d0f0c056'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-14.jpg?alt=media&token=03fd24e3-ffe0-488f-87d4-3f7b3e4962ea'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-15.jpg?alt=media&token=6b58edab-6a61-4bf3-8f39-a8eb1a4a7c48'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-16.jpg?alt=media&token=7e7acdec-9fb6-4372-a465-3cbadd44f141'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-17.jpg?alt=media&token=a8ccc3e3-57da-44a2-b9cf-7e36ddd2480e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-18.jpg?alt=media&token=8979108b-e845-4c4a-8a9a-3c086e621a5e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-19.jpg?alt=media&token=eac0bcaf-c153-4936-b638-0b2f2756d206'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U6L1%2Fe369fae98b62c6dfe92aa029e6c9a245-20.jpg?alt=media&token=cad1f0ce-e5c9-499b-a631-7622b24ea5e5'} alt="boohoo" className="img-responsive"/>
          </div>
         
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
