import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L5.scss'

export default class B11U1L5 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U3L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-0.jpg?alt=media&token=9a813386-0b8f-4d40-818e-00ea4ff117a0'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-1.jpg?alt=media&token=5a2df093-2ca6-4271-8fa6-5185d142c229'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-2.jpg?alt=media&token=2457a0fa-7c2b-419d-92a3-6c009756bbec'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-3.jpg?alt=media&token=f5460cd5-4a27-49fd-b370-a746f6aaef8e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-4.jpg?alt=media&token=cddd4d1c-e64d-499f-a5a2-50bc7c274a82'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-5.jpg?alt=media&token=e4de3bc8-1363-443c-8780-5481de4264a9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-6.jpg?alt=media&token=919a639f-9355-4395-b460-eee20922447f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-7.jpg?alt=media&token=00e6e9db-d717-456a-bbf9-41e49bb0de5d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-8.jpg?alt=media&token=a144b4dd-5e94-4549-9a81-7ae3ce4b0573'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-9.jpg?alt=media&token=42b7dfe3-6442-4dbb-869f-a381c4178847'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-10.jpg?alt=media&token=dba5703e-60a5-4468-8219-8503b5467876'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-11.jpg?alt=media&token=92c616cf-33a2-4f49-8e9f-a1f91f82d9fa'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-12.jpg?alt=media&token=783d4a05-4f80-4496-b797-5d4efc7e7764'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-13.jpg?alt=media&token=4d627944-40a2-4a3c-b75b-49679c1634ee'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-14.jpg?alt=media&token=1dc353c5-5019-4185-a90d-1bf2a93a96a1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-15.jpg?alt=media&token=a0e00213-d721-4eb0-9df3-9279e9efa15f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-16.jpg?alt=media&token=dd89e332-4a79-425f-b5f2-9765681e43a7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L2%2Fdeee7923c8a3816a816bbf7124b778f6-17.jpg?alt=media&token=433e3c47-c392-4602-aebe-1c30df1f808c'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
