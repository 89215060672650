import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L6.scss'

export default class B11U1L6 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U4L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-0.jpg?alt=media&token=7a164c2a-b898-43fe-9625-aca154f47fd1'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-1.jpg?alt=media&token=129e8b4b-2d84-479a-be18-61773599726c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-2.jpg?alt=media&token=00c50375-251b-4496-a57e-390ca3e86e31'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-3.jpg?alt=media&token=bde044d8-fc00-43b5-92ad-24af37379e68'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-4.jpg?alt=media&token=78522b9e-7ce7-44e0-a136-e5c80305ca12'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-5.jpg?alt=media&token=f1e97afd-b823-45ae-ab24-59a381535d97'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-6.jpg?alt=media&token=8837f962-2a9e-401d-a073-3230a87b6e61'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-7.jpg?alt=media&token=ee20f15c-e3bc-4c0a-9e58-5ad0e577589b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-8.jpg?alt=media&token=2b7ad106-b10d-4558-89fe-82f2db7a81a0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-9.jpg?alt=media&token=cc7f2d8e-f971-4f95-abdc-5ff70c6daa71'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-10.jpg?alt=media&token=c3fd3f9f-7d2f-45e5-89a9-8e9671589fd8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-11.jpg?alt=media&token=79dec66e-403f-4f45-bd57-c3fd5b66da7b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-12.jpg?alt=media&token=ba0266ac-f0ee-4a9c-a65e-ce058be3a3a7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-13.jpg?alt=media&token=93c48d6a-7cce-459a-9762-a333b5edeef1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-14.jpg?alt=media&token=174d4228-3c92-4a99-8407-ddca279e07f1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-15.jpg?alt=media&token=120a97c3-fe5a-43e6-99e5-496b66d86663'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-16.jpg?alt=media&token=5c641633-68a0-4b0a-9c7d-f183c81690b8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-17.jpg?alt=media&token=88e2b728-8e5b-4565-830c-226b777c1568'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-18.jpg?alt=media&token=5d414bbf-afa1-4f5c-ace9-b92c0a9b9771'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-19.jpg?alt=media&token=5ed90021-3571-4e9a-970d-e3b850b96b75'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-20.jpg?alt=media&token=983f1dc4-25cd-4387-bb68-00962339046f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L1%2F240aa754e29502443778dc11bf66fe54-21.jpg?alt=media&token=5c96c966-a217-4976-bbb3-c97857909cd2'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
