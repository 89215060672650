import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L7.scss'

export default class B11U1L7 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U4L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-0.jpg?alt=media&token=b095ccad-7d10-42c0-8b8e-3b9187f7384c'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-1.jpg?alt=media&token=b2a503af-d645-45bd-8889-7b7fbc5563a2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-2.jpg?alt=media&token=3ecaba94-b2d7-4240-8897-56e7e0492fc6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-3.jpg?alt=media&token=84135ea3-0558-4d09-9b41-2cd86ee14056'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-4.jpg?alt=media&token=99ed9f30-3c5a-4b0e-b29b-8eff7265b83a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-5.jpg?alt=media&token=c392ed32-9a22-4117-940e-9586bfb6ac35'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-6.jpg?alt=media&token=92554a19-2470-4fb0-8561-68735222c4ae'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-7.jpg?alt=media&token=83095c3a-5455-4030-a7f7-fc573310de51'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-8.jpg?alt=media&token=c9dde747-dc36-4aac-9f93-f7a823ef6717'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-9.jpg?alt=media&token=1d57abe5-64db-4c4c-94d7-64c7c42dc708'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-10.jpg?alt=media&token=5c3f1010-49c7-434d-9ff4-2a1f478e709d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-11.jpg?alt=media&token=f937d3b1-d8cd-4d1e-aec2-a0ae2650c0b9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-12.jpg?alt=media&token=8aad4ec2-00ad-4346-9124-7f5eba3b4857'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-13.jpg?alt=media&token=064c8757-5808-4d33-9caa-7bc13fa34174'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-14.jpg?alt=media&token=614eb15f-0753-4e1b-9365-8bece2a1ec87'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-15.jpg?alt=media&token=22eec471-0620-4f78-8421-4baff0697a11'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-16.jpg?alt=media&token=3be58292-2e29-4156-8a2b-6b1d9e751c55'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-17.jpg?alt=media&token=7b9fd2da-6a66-4a3a-96e2-6ad768ce062f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-18.jpg?alt=media&token=b1e57117-3af5-4259-ba77-44da668aed50'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-19.jpg?alt=media&token=9a91941d-8c86-41fb-98ad-7e0cc3607c39'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-20.jpg?alt=media&token=935ca6f4-ce43-4df3-8d8e-976f6378a912'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-21.jpg?alt=media&token=24d1d5d3-50da-496f-8493-44ed45a6bc1d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-22.jpg?alt=media&token=7396508c-5672-4ff0-9195-c66f82bf1ad7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-23.jpg?alt=media&token=266fe1bb-167b-42f5-961b-efcb2d771ae5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U4L2%2F487e9bd693502245abc14db12b188ba0-24.jpg?alt=media&token=9a68f2ef-3266-406e-b05b-e002c6d6acb2'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
