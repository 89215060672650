import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L8.scss'

export default class B11U1L8 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U5L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-0.jpg?alt=media&token=1228a82d-655c-4f11-a503-2b5410269734'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-1.jpg?alt=media&token=39adc015-2399-4918-9026-0c0ebe007f55'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-2.jpg?alt=media&token=fb178600-efba-47ea-9fc8-36e8f622d6f1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-3.jpg?alt=media&token=ed3172c0-7371-4db1-b1ac-4e5b4ef524c2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-4.jpg?alt=media&token=a842b2b6-5667-4d91-8b79-a9eb05ecebc5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-5.jpg?alt=media&token=7a2f814c-717c-4445-8ccc-a9e9300deaf7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-6.jpg?alt=media&token=744bdfb6-a013-4a20-8846-af69011be188'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-7.jpg?alt=media&token=9235f1e7-a3e0-4841-b9bb-406366ca74c3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-8.jpg?alt=media&token=5c9b3823-4654-4a06-a531-b5a79d27610a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-9.jpg?alt=media&token=e41680eb-dd00-4a12-8eda-dd23a66f4a2b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-10.jpg?alt=media&token=c102be7c-cf22-4d5a-b53d-ae63d52fb557'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-11.jpg?alt=media&token=0d745a78-9b81-42ae-b672-d6bd4d0b3791'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-12.jpg?alt=media&token=97d7c84c-585c-4267-bbd1-243ee94f6e52'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-13.jpg?alt=media&token=e02e9268-3895-47b7-8f57-d4770ec05464'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-14.jpg?alt=media&token=eaec9b09-e9bc-4170-8f13-761fea227b43'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-15.jpg?alt=media&token=fbaded3c-2ee8-4401-abf0-068a5d9a98e2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-16.jpg?alt=media&token=4d9d87c1-0aca-4b0b-9593-0f0913890f1d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-17.jpg?alt=media&token=be8eec20-b58d-4ac2-8508-cfa7a08165d3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-18.jpg?alt=media&token=81f7a487-97eb-4a71-a9c2-3a177c194310'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-19.jpg?alt=media&token=ecf6a273-ac88-4500-a204-2a3f48b0a691'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-20.jpg?alt=media&token=54f78ae6-86e4-4861-b190-2470aa1debef'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-21.jpg?alt=media&token=86a1d82d-c9dd-4cff-afa4-ceafa9f28cae'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L1%2F6886bcfa8dfce101726c8fc23cb05e76-22.jpg?alt=media&token=9be48532-b98b-47d3-a5d3-449c6155696e'} alt="boohoo" className="img-responsive"/>
          </div>
         
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
