import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { Logo } from "../../logo.png";

import "bootstrap/dist/css/bootstrap.min.css";

import './Hamburgesa.scss'

export default function Hamburgesa() {

    return (
        <Navbar collapseOnSelect expand={false} bg="dark" variant="dark">
      <Navbar.Brand href="/">
        
        Teacher Training  
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/lesson1">M1U1L1</Nav.Link>
          <Nav.Link href="/lesson2">M1U2L1</Nav.Link>
          <Nav.Link href="/lesson3">M1U2L2</Nav.Link>
          <Nav.Link href="/lesson4">M1U3L1</Nav.Link>
          <Nav.Link href="/lesson5">M1U3L2</Nav.Link>
          <Nav.Link href="/lesson6">M1U4L1</Nav.Link>
          <Nav.Link href="/lesson7">M1U4L2</Nav.Link>
          <Nav.Link href="/lesson8">M1U5L1</Nav.Link>
          <Nav.Link href="/lesson9">M1U5L2</Nav.Link>
          <Nav.Link href="/lesson10">M1U6L1</Nav.Link>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
    )
}
