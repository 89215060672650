import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L9.scss'

export default class B11U1L9 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U5L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-0.jpg?alt=media&token=991397ed-a308-4637-8f02-6fbca99152e4'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-1.jpg?alt=media&token=bfc218b2-eaee-43d4-8471-282db87f8a34'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-2.jpg?alt=media&token=3f4bb6a6-3c0d-49f9-ada3-f3add498a526'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-3.jpg?alt=media&token=419c783f-0777-4c54-b7aa-fe954a23f624'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-4.jpg?alt=media&token=7b64a6d0-4c74-4fc2-9d78-1b90c1fa80dd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-5.jpg?alt=media&token=9c306961-1e80-44bf-b534-952ceef59c0e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-6.jpg?alt=media&token=b457e040-68fa-4bb7-add5-7d399b81d680'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-7.jpg?alt=media&token=ac1e47f3-751b-4fbc-8142-999c572a4b15'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-8.jpg?alt=media&token=7f502853-2792-4f6e-a140-b4f6409523c0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-9.jpg?alt=media&token=a0d8360e-7490-4670-aab5-25785a700c54'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-10.jpg?alt=media&token=ea898996-c833-46c8-b0e6-fabbd32f184c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-11.jpg?alt=media&token=bd70e4f9-b054-4cfb-b898-a40eddfd94b6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-12.jpg?alt=media&token=3586cef3-d7d8-46e4-9c6d-ae8e7846a90d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-13.jpg?alt=media&token=165b93e0-2c18-4641-a437-75303cfbd3a0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-14.jpg?alt=media&token=e7284aff-b8a7-4a99-a35b-660f51587ea0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-15.jpg?alt=media&token=51967405-69b7-440f-8d90-7fd1808ffce3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-16.jpg?alt=media&token=532beb60-d562-4e09-8548-3604efb0ecc2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-17.jpg?alt=media&token=66742fed-520f-48ff-9acd-d72dcc923cef'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-18.jpg?alt=media&token=136cfa27-d626-4fe9-aed0-05ccd07ae6df'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-19.jpg?alt=media&token=02bc9145-bc06-45bf-b80a-4e81b9235450'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-20.jpg?alt=media&token=125906b9-c5eb-4eb7-9b8c-1c4ae866aef8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-21.jpg?alt=media&token=c0d66f5f-89e0-40e1-81b4-4e7bb35f5f92'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U5L2%2Ffe7708eacbd217bda321e258fbfa6eb1-22.jpg?alt=media&token=228e5bbb-d721-4cbe-8794-f75bce327a97'} alt="boohoo" className="img-responsive"/>
          </div>
         

          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
