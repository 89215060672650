
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (

      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
<Grid>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2><br></br>M1U1L1</h2>
    <Slider {...settings}>

 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-0.jpg?alt=media&token=e5a7bf02-2a65-49c9-b5bc-03976192f82f'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
  
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-1.jpg?alt=media&token=57a4d51e-c926-499c-a4ff-d194d681b44d'} alt="boohoo" className="img-responsive"/>
          </div> 
      
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-2.jpg?alt=media&token=acbf36ff-530c-4aad-9815-a41431de4846'} alt="boohoo" className="img-responsive"/>
          </div>
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-3.jpg?alt=media&token=a08886f1-738e-4b95-abd2-c559af65485b'} alt="boohoo" className="img-responsive"/>
          </div>
     
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-4.jpg?alt=media&token=835ca02c-f017-410a-8b94-963c43d2826a'} alt="boohoo" className="img-responsive"/>
          </div>
    
      
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-5.jpg?alt=media&token=0be1ddc0-f9bf-4060-8a22-7c60d2307b40'} alt="boohoo" className="img-responsive"/>
          </div>
      
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-6.jpg?alt=media&token=91391686-d574-4c87-bfe2-27b2cce19f2d'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-7.jpg?alt=media&token=514b6feb-ae06-469b-8ae1-f3e828bd610b'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-8.jpg?alt=media&token=7a069934-cc72-4ab1-bff7-f399c98e5607'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-9.jpg?alt=media&token=c77104bd-91ab-4275-b361-384c4422cd2c'} alt="boohoo" className="img-responsive"/>
          </div>
    

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-10.jpg?alt=media&token=14295c74-fa17-48ba-8096-baae55acfe02'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-11.jpg?alt=media&token=c54165dd-5a7e-4722-9cea-3f046eb18efd'} alt="boohoo" className="img-responsive"/>
          </div>
      

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-12.jpg?alt=media&token=ea546f47-6683-49cb-81f2-86cd17edce46'} alt="boohoo" className="img-responsive"/>
          </div>
          

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-13.jpg?alt=media&token=05714ccc-c1bb-48eb-873f-17859d693be1'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-14.jpg?alt=media&token=7cd1b88a-fb3c-4ed1-a84a-e20dedd41a61'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-15.jpg?alt=media&token=3ca71467-901b-4a88-b64b-0286e8cf2bc7'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-16.jpg?alt=media&token=203673d7-a087-4221-80c9-28db3ff0cea1'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U1L1%2Ff97d4f81a1ed31787a0594ab7496e34c-17.jpg?alt=media&token=e1960e0a-0d63-45a4-8567-a6a02442dcf3'} alt="boohoo" className="img-responsive"/>
          </div>
        

        </Slider>
      </>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
      //   </header>
      //   </div>
      // </div>
    );
  }
}
