import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L2.scss';

export default class B11U1L2 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
      <Grid>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
        <h2><br></br>M1U2L1</h2>
        <Slider {...settings}>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-0.jpg?alt=media&token=583a6316-45c6-416b-9da4-076b2ef36b85'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-1.jpg?alt=media&token=4d3e2c80-0315-4720-add2-f71b61d45e0b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-2.jpg?alt=media&token=fbb95968-47da-4afb-90f5-d03b7eaece60'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-3.jpg?alt=media&token=58f1a5a4-4359-401f-a44d-82d2409b79aa'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-4.jpg?alt=media&token=94820f17-42c2-4178-9a9b-ba4db89e1379'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-5.jpg?alt=media&token=aa5cbf5d-cb3a-41d7-8f93-4a687f646789'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-6.jpg?alt=media&token=0c091e93-196b-4d22-88fb-23138becab99'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-7.jpg?alt=media&token=4e1285d0-14f4-4c75-8bca-61dbabcdcf5a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-8.jpg?alt=media&token=5af0a4ad-4c91-4f06-b9c4-d40785fca395'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-9.jpg?alt=media&token=59f4d5f0-3de5-458b-8c42-bfa54bbd94ae'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-10.jpg?alt=media&token=160bb50e-c638-4a13-b921-7d23573b6703'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-11.jpg?alt=media&token=8dcdb26e-f4d4-4ce2-9c16-3df4462ca9db'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-12.jpg?alt=media&token=714dee1e-f387-47b8-a00c-9657bac96844'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-13.jpg?alt=media&token=73bb819e-fcb5-4224-b81c-66916ccf8357'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-14.jpg?alt=media&token=7e7e09c8-0820-4ee1-b02e-3b7e1a6f0880'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-15.jpg?alt=media&token=f80176b6-2dae-4fac-9636-3816899885ac'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-16.jpg?alt=media&token=4fefe491-05a6-4007-abe3-4a916e365f47'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-17.jpg?alt=media&token=3299267d-212b-4ff3-8c6a-c3b461389ad7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-18.jpg?alt=media&token=61e8357d-a7f5-463b-a4fa-f9ae580417f2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-19.jpg?alt=media&token=6353f776-3868-4421-9db9-8ffc499a572b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-20.jpg?alt=media&token=f77af088-33da-4ed5-bb74-8d03dd6f52f5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-21.jpg?alt=media&token=56a6a4fd-9573-4c27-a4e9-38a757fe0a93'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-22.jpg?alt=media&token=59cd3bc9-85e0-441e-85d2-44a88ea2764f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-23.jpg?alt=media&token=d142800f-f792-415a-b46c-5ee93a8b8a5b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-24.jpg?alt=media&token=67316a79-fa06-414b-b35b-0163dc90f36d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-25.jpg?alt=media&token=89cbd51f-1dad-4245-8e48-71d9c5cca8d5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U2L1%2Fb91fff5223f53a539b978ecc2f56705b-26.jpg?alt=media&token=812434a4-67fa-473d-b163-6c3ddbc4f1e0'} alt="boohoo" className="img-responsive"/>
          </div>
         

        </Slider>
       
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
        
      //   </header>
      // </div>
      // </div>
    
    );
  }
}
