import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L4.scss'

export default class B11U1L4 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M1U3L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-0.jpg?alt=media&token=55fceaca-2a60-4c6e-989d-4743801bbf40'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-1.jpg?alt=media&token=d8902ae6-b5fb-4a84-935c-b737ee78e674'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-2.jpg?alt=media&token=eb296b0d-bcb9-4998-8603-f6f0e7fffed3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-3.jpg?alt=media&token=07c8d650-27fd-42d1-8025-c3c007f5a0b4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-4.jpg?alt=media&token=2bd2d152-074d-4003-96f0-ce741d93cf59'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-5.jpg?alt=media&token=a0e354dc-9f35-40a5-a51d-29d5d0706073'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-6.jpg?alt=media&token=fa7d7e3d-0e31-42bd-b529-b4476d8cba6e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-7.jpg?alt=media&token=2e8b99bf-eb66-4e97-91eb-35bc7b9749ae'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-8.jpg?alt=media&token=f9c79c16-ca61-4086-8b87-df2a0a301836'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-9.jpg?alt=media&token=af8cadb8-fded-47c9-93dd-abf34d2c0a65'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-10.jpg?alt=media&token=d7ef8482-4344-4fb4-a294-a1b15582bc84'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-11.jpg?alt=media&token=deff2110-ebe6-4b56-9645-b387a2e11a34'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-12.jpg?alt=media&token=8aa9f93b-8d94-4d25-aee6-34513738f88d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-13.jpg?alt=media&token=76623693-fcb2-428f-ad13-c163f6666586'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-14.jpg?alt=media&token=ec7752b2-fecf-4ba0-8bad-f8d2279f38e8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-15.jpg?alt=media&token=eea49af3-e4d3-4c1f-b130-4e328bae3ce5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-16.jpg?alt=media&token=c957242d-380d-4dac-bbbf-716c62a0fc53'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-17.jpg?alt=media&token=a10579d1-4902-425b-a64b-503064330bf6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-18.jpg?alt=media&token=73fcf3e3-8869-4b50-a086-a3a46aab0435'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-19.jpg?alt=media&token=c9928e73-42b7-42f2-b07f-7815d17d5cd4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-20.jpg?alt=media&token=b5f44ea1-07a5-4b49-a385-e01c4584ae96'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-21.jpg?alt=media&token=0bab7d44-105e-4c9c-879f-6bcc9d7482d4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-22.jpg?alt=media&token=fb76e1ed-a065-45d5-b1d1-ff811d2923b8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%201%2FM1U3L1%2Ff366d874e09057595642d57b2056158c-23.jpg?alt=media&token=72bfea3a-7ec7-41db-b5b5-e417751bf81b'} alt="boohoo" className="img-responsive"/>
          </div>
         

        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
